import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../_actions/security.actions';

const initialState = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const ChangePassword = () => {
    const dispatch = useDispatch();
    const { loading, message, error } = useSelector((state) => state.auth);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState(initialState);

    const validate = () => {
        let tempErrors = {};

        const { currentPassword, newPassword, confirmPassword } = formData;
        if (!currentPassword) {
            tempErrors.currentPassword = "Current password is required.";
        }
        if (!newPassword) {
            tempErrors.newPassword = "New password is required.";
        } else if (newPassword.length < 6) {
            tempErrors.newPassword = "New password must be at least 6 characters long.";
        }
        if (!confirmPassword) {
            tempErrors.confirmPassword = "Please confirm your new password.";
        } else if (newPassword !== confirmPassword) {
            tempErrors.confirmPassword = "Passwords do not match!";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Returns true if no errors
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const { currentPassword, newPassword, confirmPassword } = formData; // Extract values from formData
            dispatch(changePassword(currentPassword, newPassword, confirmPassword));
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
                <h2 className="text-2xl font-semibold mb-6 text-center">Change Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Current Password</label>
                        <input
                            autoComplete='off'
                            type="password"
                            name="currentPassword" // Ensure name attribute matches the state key
                            value={formData.currentPassword}
                            onChange={handleOnChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {errors.currentPassword && <span className="text-red-500 text-xs italic">{errors.currentPassword}</span>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
                        <input
                            type="password"
                            name="newPassword" // Ensure name attribute matches the state key
                            value={formData.newPassword}
                            onChange={handleOnChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {errors.newPassword && <span className="text-red-500 text-xs italic">{errors.newPassword}</span>}
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Confirm New Password</label>
                        <input
                            type="password"
                            name="confirmPassword" // Ensure name attribute matches the state key
                            value={formData.confirmPassword}
                            onChange={handleOnChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {errors.confirmPassword && <span className="text-red-500 text-xs italic">{errors.confirmPassword}</span>}
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Changing...' : 'Change Password'}
                    </button>
                </form>
                {message && <p className="text-green-500 text-sm mt-4 text-center">{message}</p>}
                {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
            </div>
        </div>
    );
};

export default ChangePassword;
