import {
  userConstants
} from '../_constants';

export default function users(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.user.data,
        loading: false,
      };
    case userConstants.USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        userUpdatePasswordByAdmin: action.user,
        loading: false,
      };
    case userConstants.USER_PASSWORD_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
      case userConstants.BLOCK_UNBLOCK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case userConstants.BLOCK_UNBLOCK_SUCCESS:
      return {
        ...state,
        blockUnblockUser: action.user,
        loading: false,
      };
    case userConstants.BLOCK_UNBLOCK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}