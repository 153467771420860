import React from 'react'
import Card from '../../components/ui/Card/Card'
import Table from '../../components/ui/Table/Table/Table'

function Advertisment() {
    const theadData = ['Add Type', 'Size', 'Position', 'Duration', 'Cost', 'Desc']

    const tbodyData = [
        { addType: "Banner Add", size: '728x555 px', positon: 'Top Of Page', duration: '1 Month', cost: '$69', description: 'this is desc' }
    ]

    function replaceData(data) {
        console.log(data)
        return data.map((row) => ({
            'Add Type': row.addType,
            'Size': row.size,
            'Position': row.positon,
            "Duration": row.duration,
            "Cost": row.cost,
            'Desc':row.description
        }))
    }
    const dummyData = replaceData(tbodyData)

    return (
        <div>

            <div className='p-7 space-y-8'>
                <h2 className="text-2xl font-bold mb-4">Advertisment Management </h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2   w-full'>
                    <Card Aamount='6,009' title={`Add Revenue`} />
                    <Card Aamount='6,900' title={`Total Outstanding`} />
                    <Card Aamount='1169' title={`In process`} />
                    <Card Aamount='54,229' title={`Paid Today`} />
                </div>

                <div className='bg-white rounded-xl p-4'>
                    <Table theadData={theadData} tbodyData={dummyData} />
                </div>


            </div>
        </div>
    )
}

export default Advertisment