// Function to get the authorization header

export function authHeader() {
    let user = JSON.parse(window.sessionStorage.getItem('adminuser'));
    if (user && user.token) {
        // You can keep a general log for debugging in development (optional)
        console.log('Token found for user.');
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        console.error('No valid token found.'); // Log if no token is found
        return {};
    }
}


// Function to handle logout
export function logoutFunction() {
    window.sessionStorage.removeItem('adminuser');
    window.location.replace('/login');
    window.location.reload();
}

// Common header for public API requests
export const headerForPublicAPI = {
    'Content-Type': 'application/json',
};

// Header for private API requests (with Authorization)
export const headerForPrivateAPI = () => {
    const header = {
    }
    const token = authHeader().Authorization
    header["Content-Type"] = "application/json"
    header["Authorization"] = token
    return header
}


// Header for private media API requests
export const headerForPrivateMediaAPI = {
    'Content-Type': 'multipart/form-data',
    ...authHeader(), // Spread the `Authorization` token here from `authHeader`
};

// Function to call the API
export const APIcallFunction = async (credentials) => {
    const baseUrl = "http://192.168.0.56:1220/api/v1"; // Set your correct base URL
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header || { 'Content-Type': 'application/json' }, // Default to public header if not provided
        body: credentials.body ? JSON.stringify(credentials.body) : null, // Only include body if it's not null
    };

    try {
        const response = await fetch(baseUrl + credentials.endPoint, requestOptions);
        console.log('Response Data:');
        const responseData = await handleResponse(response);
        return {
            data: responseData.data,
        };
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

// Handle the response from the API
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logoutFunction(); // Handle unauthorized access by logging out
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
