import React from 'react'
import Table from '../../components/ui/Table/Table/Table';


const VarificationAndpropmation = () => {
    const theadData = [
        "ID",
        "User Name",
        "Verification Status",
        "Verified Date",
        "Pormation Tier",
        "Promation State Date",
        "Promation End Date",
        "Remarks"
    ];

    const tbodyData = [
        {
            id: "0001",
            userName: "John Doe",
            verificationStatus: "john@example.com",
            verifiedDate: "Customer",
            status: "Active",
            signupdate: "01/12/2023",
            lastlogin: "10/10/2023",
            Remarks: 'Active',

        }
    ]
    const dummyData = (data) => {
        return data.map((row) => ({
            'ID': row.id,
            'User Name': row.userName,
            'Verification Status': row.verificationStatus,
            'Verified Date': row.verifiedDate,
            'Pormation Tier': row.promotionTier,
            'Promation State Date': row.promotionStateDate,
            'Promation End Date': row.promotionEndDate,
            'Remarks': row.remarks,
        }));
    };

    const originalData = dummyData(tbodyData);
    return (
        <div className='p-5 space-y-3'>
            <h2 className="text-2xl font-bold mb-4">Verification & Promotion </h2>
            <div className="p-4 bg-white rounded-2xl  mx-auto w-full space-y-4">
                <div className='flex justify-between place-items-center'>
                    <div>
                        <h1 className="text-xl font-semibold pb-1">All Customers</h1>
                    </div>

                </div>
                <Table theadData={theadData} tbodyData={originalData} />
            </div>

        </div>
    )
}

export default VarificationAndpropmation