import React from 'react';

const EditModal = ({ isOpen, onClose, editData,handleOnChange,handleEditSubmit }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
            <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-md p-6">

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold text-gray-800">Edit Details</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 transition"
                    >
                        &times;
                    </button>
                </div>

                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name='Name'
                        value={editData?.Name || null}
                        onChange={handleOnChange}
                        placeholder="Enter your name"
                        className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300 focus:outline-none"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300 focus:outline-none"
                    />
                </div>

                <div className="flex justify-end gap-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition"
                    >
                        Close
                    </button>
                    <button
                        className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition"
                        onClick={handleEditSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditModal;
