import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarJSON from './sidebar.json';
import './Sidebar.css'
import { FaCaretRight } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";



function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const onClickMenu = (url) => {
        navigate(url);
        setTimeout(() => {
            setNavbarOpen(!navbarOpen);
        }, 150);
    };

    return (
        <>
            <button className={`${navbarOpen ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-customColor z-50 h-16 lg:hidden`} onClick={() => setNavbarOpen(!navbarOpen)}>
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"></path>
                </svg>
            </button>
            <div className={`${navbarOpen ? 'flex' : 'hidden'} bg-[#FFFFFF] fixed left-0 inset-y-0 lg:static lg:flex w-64 z-50 flex-col`}>
                <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">
                    <div onClick={() => onClickMenu('/app/dashboard')} className="cursor-pointer h-16 flex-shrink-0 px-4 p-2 bg-[#FFFFFF]  w-full z-50 justify-start text-clip">
                        <img src="/Images/logo.png" className="w-20" />
                        <p className="text-[#000000] workSans600 text-xs ps-5 uppercase">Dress well</p>
                    </div>
                    <nav className="overflow-y-auto  flex flex-col flex-wrap  justify-between relative w-64 z-10 mt-1  h-full overflow-auto shadow shadow-[#BAB6B640]">
                        <div className=" flex flex-col flex-wrap  w-full mx-auto flex-1">
                            <div className="relative z-40 flex-1 w-full p-2 ">
                                {SidebarJSON.WebView.map((ele, index) => (
                                    <ul className="md:flex-col md:min-w-full flex flex-col list-none" key={index}>
                                        <li className={`flex justify-between place-items-center p-3 rounded-lg   text-[20px] w-full ${location.pathname === ele.location ? "bg-[#84315E]" : ""}`}>
                                            <Link className={`text-sm capitalize  font-bold block ${location.pathname === ele.location ? "text-white" : "text-blueGray-700 hover:text-black"}`} to={ele.location}>
                                                {ele.label}
                                            </Link>
                                            {location.pathname === ele.location && <FaCaretRight className="ml-2 text-white" />}
                                        </li>
                                    </ul>
                                ))}
                            </div>
                            <div className=" bottom-16 mx-auto p-2 w-full">
                                <p className="w-[95%] mb-4 ms-1 border p-2 rounded-md flex justify-start place-items-center gap-3 bg-white hover:backdrop-blur-sm hover:text-white hover:bg-black transition-colors duration-200 cursor-pointer drop-shadow-sm">
                                    <CiLogout />
                                    Logout
                                </p>
                            </div>


                        </div>

                    </nav>

                </div>
            </div>
        </>
    );
}

export default Sidebar;
