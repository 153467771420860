import { LOGIN_HISTORY_FAILURE, LOGIN_HISTORY_SUCCESS, LOGIN_HISTORY_REQUEST } from "../_constants/loginHistory.constants";


const initialState = {
    loading: false,
    loginHistory: [],
    message: '',
    error: null,
};

const loginHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_HISTORY_REQUEST:
            return { ...state, loading: true, error: null };
        case LOGIN_HISTORY_SUCCESS:
            return { ...state, loading: false, loginHistory: action.payload.data.list, message: action.payload.message };
        case LOGIN_HISTORY_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default loginHistoryReducer;