import { LOGIN_HISTORY_FAILURE, LOGIN_HISTORY_SUCCESS, LOGIN_HISTORY_REQUEST } from '../_constants/loginHistory.constants';
import { authHeader, APIcallFunction } from '../_helpers';

export const getLoginHistory = (filters) => async (dispatch) => {
    dispatch({ type: LOGIN_HISTORY_REQUEST });

    const credentials = {
        method: 'POST',
        endPoint: '/admin/getLoginHistory',
        header: {
            'Authorization': authHeader().Authorization,
            'Content-Type': 'application/json'
        },
        body: filters
    };

    try {
        const response = await APIcallFunction(credentials);
        dispatch({ type: LOGIN_HISTORY_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: LOGIN_HISTORY_FAILURE, payload: error });
    }
};