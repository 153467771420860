import React from 'react';
import DashboardJSON from './Dashboard.json'
import Table from '../../components/Atoms/Tables/index.js';
import Card from '../../components/ui/Card/Card.js';
import PopularItem from './PopularItems.js';
import RevenueCard from './RevenueCard.js';


const Dashboard = () => {

  const popularItems = [
    {
      image: 'https://plus.unsplash.com/premium_photo-1682095757120-c9abb908ed60?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Lorem Ipsum',
      description: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
    },
    {
      image: 'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Lorem Ipsum',
      description: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
    },
    {
      image: 'https://plus.unsplash.com/premium_photo-1682095757120-c9abb908ed60?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Lorem Ipsum',
      description: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
    },
    {
      image: 'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Lorem Ipsum',
      description: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
    },
  ];

  const revenueData = {
    revenue: 4500.089,
    loss: 8,
    profit: 28,
  };


  return (
    <>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className=" sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-4 w-full'>
                          <Card Aamount='6,009' title={`Overdue`} />
                          <Card Aamount='6,900' title={`Total Outstanding`} />
                          <Card Aamount='1169' title={`In process`} />
                          <Card Aamount='54,229' title={`Paid Today`} />
                        </div>
                      </sectiion>
                      <section className=''>
                        <div className="p-8  min-h-screen">
                          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 ">
                            <div className='p-4'>
                              <h1 className="text-2xl font-bold mb-4">Popular Items</h1>
                              <div className="space-y-4 bg-white p-4 drop-shadow-lg shadow-[#8A878740]/25 rounded-2xl">
                                {popularItems.map((item, index) => (
                                  <PopularItem
                                    key={index}
                                    image={item.image}
                                    title={item.title}
                                    description={item.description}
                                  />
                                ))}
                              </div>
                            </div>
                            <div>
                              <RevenueCard
                                revenue={revenueData.revenue}
                                loss={revenueData.loss}
                                profit={revenueData.profit}
                              />
                            </div>
                          </div>
                        </div>

                      </section>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
