import React from 'react';

const RevenueCard = ({ revenue, loss, profit }) => {
  return (
    <div className=" rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Revenue for Loyalty Program</h2>
      <div className='flex justify-between  place-items-center'>
        <p className="text-2xl font-bold mb-2">${revenue.toFixed(3)}</p>
        <div className="flex justify-between gap-4">
          <div>
            <p className="text-sm text-gray-500">Loss</p>
            <p className="text-lg font-bold text-red-500">{loss}%</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Profit</p>
            <p className="text-lg font-bold text-green-500">{profit}%</p>
          </div>
        </div>
      </div>
      <div className='w-full h-[55vh] bg-white drop-shadow-md shadow-[#8A878740]/25 rounded-2xl mt-4'>

      </div>
    </div>
  );
};

export default RevenueCard;
