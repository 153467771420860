import React from 'react'

const Input = ({ type = "text", placeholder, value, onChange }) => {
    return (
        <div className="relative">
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className="w-full p-2 pl-10 bg-[#F9FBFF] rounded-xl focus:outline-none focus:ring focus:ring-indigo-300"
            />
            <div className="absolute inset-y-0 left-2 flex items-center text-gray-500">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.73 3.02l4.27 4.27a1 1 0 01-1.42 1.42l-4.27-4.27A6 6 0 012 8z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        </div>

    )
}

export default Input