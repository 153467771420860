import React from 'react'
import Table from '../../components/ui/Table/Table/Table'

const Community = () => {
    const theadData=['Column Name','Description']
    const tbodyData=[{columnName:'User ID',description:'uniquie identifier for each comunity member'}]

    function replaceData(data){
        return data.map((row)=>({
            'Column Name':row.columnName,
            'Description':row.description

        }))
    }

    const dummyData=replaceData(tbodyData)

    return (
        <div>
            <div className='p-7 space-y-8'>
                <h2 className="text-2xl font-bold mb-4">Community </h2>
                <div className='bg-white p-4 rounded-xl'>
                    <Table theadData={theadData} tbodyData={dummyData}/>
                </div>
            </div>
        </div>
    )
}

export default Community