import React, { useState } from 'react'
import Input from '../../components/ui/Input/Input';
import Select from '../../components/ui/Select/Select';
import Table from '../../components/ui/Table/Table/Table';
import { retry } from '@reduxjs/toolkit/query';

const ProductMangment = () => {
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('active');
    const filterOptions = [
        { label: 'Approve', value: 'approve' }
    ]
    const theadData = ["Column", "Description", "Type",];

    const tbodyData = [
        {
            "column": "0001",
            'description': "unique identifire for the product",
            'type': "john@example.com",

        },
        {
            "column": "0001",
            description: "name of the product",
            type: "john@example.com",
        },
        {
            "column": "0001",
            description: "John Doe",
            type: "john@example.com",
        }
    ];

    function changeData(data){
        return data.map((row)=>({
            'Column':row.column,
            'Description':row.description,
            'Type':row.type
        }))
    }

    let dummyData=changeData(tbodyData)
    return (
        <div className='container mx-auto p-4 space-y-10'>
            <h2 className="text-2xl font-bold mb-4">Product Management</h2>
            <div className='bg-white p-4 rounded-2xl'>
                <div className='flex justify-between place-items-center'>
                    <div>
                        <h1 className="text-xl font-semibold pb-1">All Customers</h1>
                    </div>
                    <div className="flex gap-4 mb-4">
                        <Input
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Select
                            options={filterOptions}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </div>

                </div>

                <Table tbodyData={dummyData} theadData={theadData} />

            </div>
        </div>
    )
}

export default ProductMangment