import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import authReducer from './security.reducer';
import loginHistoryReducer from './loginHistory.reducer';

const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  auth: authReducer,
  loginHistory: loginHistoryReducer,
  

});

export default rootReducer;
