import { userConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, authHeader, headerForPrivateAPI, headerForPublicAPI, logoutFunction } from '../_helpers';



export const userActions = {
    login,
    logout,
    updatePasswordByAdmin,
    fetchUserList
};

function login(data, navigate) {
    const credential = {
        header: headerForPublicAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/admin/login',
    };
    return (dispatch) => {
        dispatch(request({ data }));

        // Pass 's' instead of 'credentials' to the API call function
        APIcallFunction(credential)
            .then(
                (user) => {
                    window.sessionStorage.setItem("adminuser", JSON.stringify(user.data));
                    dispatch(success(user));
                    dispatch(alertActions.success("Login Successfully"));

                    if (navigate) {
                        navigate('/app/dashboard');
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function logout() {
    logoutFunction();
    return { type: userConstants.LOGOUT };
}


function fetchUserList(data) {
    const credential = {
        method: 'POST',
        header: headerForPrivateAPI(),
        body: data,
        endPoint: "/user/list"
    }
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credential).then((user) => {
            dispatch(success(user));
            // dispatch(alertActions.success(user?.message))
        },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: userConstants.USER_LIST_REQUEST, user }; }
    function success(user) { return { type: userConstants.USER_LIST_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.USER_LIST_FAILURE, error }; }

}


function updatePasswordByAdmin(data) {
    const credential = {
        method: 'POST',
        header: headerForPrivateAPI(),
        body: data,
        endPoint: "/user/updatePasswordByAdmin"
    }

    console.log("ffffffffffffff", credential)

    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credential).then((user) => {
            dispatch(success(user));
            // dispatch(alertActions.success(user?.message))
        },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) { return { type: userConstants.USER_PASSWORD_UPDATE_REQUEST, user }; }
    function success(user) { return { type: userConstants.USER_PASSWORD_UPDATE_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.USER_PASSWORD_UPDATE_FAIL, error }; }

}

export const blockUnblockUser = (userId, actionType, reason) => async (dispatch) => {
    dispatch({ type: userConstants.BLOCK_UNBLOCK_REQUEST });

    const body = {
        userId,
        action: "UN_BLOCK", 
        reason,
    };

    const credentials = {
        method: 'POST',
        endPoint: '/user/userStatusUpdateBlockCreatorNotCreator',
        header: {
            'Authorization': authHeader().Authorization,
            'Content-Type': 'application/json',
        },
        body: body,
    };

    try {
        const response = await APIcallFunction(credentials);
        dispatch({ type: userConstants.BLOCK_UNBLOCK_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: userConstants.BLOCK_UNBLOCK_FAILURE, payload: error });
    }
};
