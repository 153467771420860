import React from 'react'
import Table from '../../components/ui/Table/Table/Table'

const CommissionRevenueManagement = () => {
    const theadData = ['Agent Id', 'Agent Name', 'Reveneu Id', 'Product Service', 'Revenue Date', 'Total Revenue', 'Commission Rate (%)', 'Commission Amount', 'Commission Date', 'Payment Status', 'Payment Date', 'Region', 'Client Name', 'Notes']
    const tbodyData=[{agentId:'001',agentName:'Pankaj',revenueId:'#0001'}]
    function changeData(data){
        return data.map((row)=>({
            'Agent Id':row.agentId,
            'Agent Name':row.agentName
        }))
    }
    const dummyData=changeData(tbodyData)
    return (
        <div>
            <div className="p-7 space-y-8">
                <div className="flex justify-between place-items-center">
                    <h2 className="text-2xl font-bold mb-4">Commisson & Revenue Mangment </h2>
                </div>
                <div className='bg-white p-4 rounded-xl'>
                    <Table theadData={theadData} tbodyData={dummyData}/>
                </div>
            </div>
        </div>
    )
}

export default CommissionRevenueManagement