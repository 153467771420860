import React from 'react'
import Table from '../../components/ui/Table/Table/Table'

const TransactionManagement = () => {
    const theadData=['Column Name','Data Type','Description']
    const tbodyData=[{columnName:'user_Id',dataType:"968071",desc:'Unique Id for eveary user'}];
    function changeData(data){
        return data.map((row)=>({
            "Column Name":row.columnName,
            'Data Type':row.dataType,
            'Description':row.desc
        }))
    }

    const dummmyData=changeData(tbodyData)
    return (
        <div>
            <div className="p-7 space-y-8">
                <div className="flex justify-between place-items-center">
                    <h2 className="text-2xl font-bold mb-4">Transaction Management</h2>
                </div>
                <div className='bg-white rounded-xl p-4'>
                    <Table theadData={theadData} tbodyData={dummmyData}/>
                </div>
            </div>
        </div>
    )
}

export default TransactionManagement