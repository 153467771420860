import React, { useState } from 'react';
import Button from '../../components/ui/Button/Button';
import Table from '../../components/ui/Table/Table/Table';

const SupportsAndQueries = () => {
    const [isActive, setIsActive] = useState('faq'); 

    const handleActive = (activeButton) => {
        setIsActive(activeButton);
    };
    const faqThead=['Ticket Id','User Name','Date Submited','Queary Type','Priority','Status','Assigned To','Respomce Time','Notes/Update']
    const updateThead=['Tckect Id','User Name','Date Submited','Queary Type','Priority','Status','Assigned To','Respomce Time','Notes/Update']
    const tbodyData=[
        {ticketId:'001',userName:'Pankaj Jangid',dateSub:"11-11-2024",quearyType:'Billing',priority:'High'}
    ]

    function chnageData(row){
        return row.map((row)=>({
            "Ticket Id":row.ticketId,
            'User Name':row.userName,
            'Date Submited':row.dateSub
        }))
    }

    const dummyData=chnageData(tbodyData)

    return (
        <div>
            <div className="p-7 space-y-8">
                <div className="flex justify-between place-items-center">
                    <h2 className="text-2xl font-bold mb-4">Support & Queries</h2>
                    <div className="flex justify-start flex-wrap gap-2 place-items-center">
                        <Button
                            label="Faq"
                            className={`rounded-xl w-[120px] p-2.5 ${
                                isActive === 'faq' ? 'bg-[#6B2C4A] text-white' : 'bg-[#D9D9D9] text-black'
                            }`}
                            onClick={() => handleActive('faq')}
                        />
                        <Button
                            label="Update Documents"
                            className={`rounded-xl p-2.5 ${
                                isActive === 'updateDocuments' ? 'bg-[#6B2C4A] text-white' : 'bg-[#D9D9D9] text-black'
                            }`}
                            onClick={() => handleActive('updateDocuments')}
                        />
                    </div>
                </div>

                <div className="mt-4 bg-white rounded-xl p-4">
                    {isActive === 'faq' && <div><Table theadData={faqThead} tbodyData={dummyData}/></div>}
                    {isActive === 'updateDocuments' && <div><Table theadData={updateThead}/></div>}
                </div>
            </div>
        </div>
    );
};

export default SupportsAndQueries;
