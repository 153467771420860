import React from 'react'

const Card = ({Aamount,title}) => {
  return (
    <div>
        <div className='bg-[#FFFFFF] drop-shadow-lg  shadow-[#857D7D40]/20 p-4 rounded-lg w-[280px] h-[100px]'>
            <div className='space-y-1 p-1'>
                    <h2 className='text-2xl font-semibold'>${Aamount}</h2>
                    <p className='text-[#8C8C8C]'>{title}</p>
            </div>
        </div>
    </div>
  )
}

export default Card

