import React from 'react'
import Table from '../../components/ui/Table/Table/Table'

const CommunityModeration = () => {
    const theadData = ['Content', 'Report Reason', 'Status', 'Report By', 'Action Token']

    const tbodyData = [
        { content: "Banner Add", reportReason: '728x555 px', status: 'In progress', reoportBy: '1 Month', actionToken: '$69' }
    ]

    function replaceData(data) {
        console.log(data)
        return data.map((row) => ({
            'Content': row.content,
            'Report Reason': row.reportReason,
            "Status": row.status,
            "Report By": row.reoportBy,
            'Action Token': row.actionToken
        }))
    }
    const dummyData = replaceData(tbodyData)

    return (
        <div>
            <div className='p-7 space-y-8'>
                <h2 className="text-2xl font-bold mb-4">Community Moderation</h2>
                <div className='grid grid-cols-2 gap-8 flex-wrap'>
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Content Monitoring</h2>
                        <div className='bg-white rounded-xl p-8'>
                            <Table theadData={theadData} tbodyData={dummyData} />
                        </div>
                    </div>
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Content Handling</h2>
                        <div className='bg-white rounded-xl p-8'>
                            <Table theadData={theadData} tbodyData={dummyData} />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CommunityModeration