import React, { useState } from 'react'
import Input from '../../components/ui/Input/Input';
import Select from '../../components/ui/Select/Select';
import Table from '../../components/ui/Table/Table/Table';
import Button from '../../components/ui/Button/Button';

const OrderMangment = () => {

    const [activeTab, setActiveTab] = useState('Order Monitoring')
    const [isChecked, setIsChecked] = useState(false);

    const theadData = ["Order Id", "Coustermer Name", "Order Date", 'Quantity', 'Order Status', 'Total Price', 'Payment Method', 'Shipping Address', 'Tracking Number', 'Shiping Status', 'Actions'];

    const tbodyData = [
        {
            "orderId": "0001",
            'coustermerName': "unique identifire for the product",
            'orderDate': "john@example.com",
            Quantity: '1',
            orderStatus: '2',
            totalPrice: "122",
            paymentMethod: 'payPal',
            shippingAddress: 'JHUNJHUNU',
            trackingNumber: '69',
            shipingStatus: '2',
        }
    ];
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    function changeData(data){
        return data.map((row)=>({
            'Order Id':row.orderId,
            'Coustermer Name':row.coustermerName,
            'Order Date':row.orderDate
        }))
    }

    let dummyData=changeData(tbodyData)
    const handleEdit=(data)=>{

    }
    return (
        <div className='container mx-auto p-4 space-y-10'>
            <h2 className="text-2xl font-bold mb-4">Order Management</h2>
            <div className='bg-white p-4 rounded-2xl space-y-4'>
                <div className='flex justify-between place-items-center'>
                    <div>
                        <h1 className="text-xl font-semibold pb-1">All Customers</h1>
                    </div>

                    <div className="relative w-60 h-12  rounded-xl bg-[#D9D9D9]">
                        <input
                            type="checkbox"
                            id="pricing-plan-switch"
                            className="absolute inset-0 z-10 cursor-pointer opacity-0"
                            checked={isChecked}
                            onChange={handleToggle}
                        />
                        <label
                            htmlFor="pricing-plan-switch"
                            className="relative flex justify-between items-center h-full w-full text-center text-sm font-medium"
                        >
                            <span
                                className={`absolute top-0 left-0 h-12 w-[50.9%] bg-[#84315E] text-white rounded-xl shadow-md transition-all duration-300 ${isChecked ? "translate-x-full bg-[#84315E]" : "translate-x-0 "
                                    }`}
                            ></span>
                            <span
                                className={`relative z-20 text-center ps-2 transition-colors duration-300 ${isChecked ? "text-black text-xs font-semibold" : "text-white text-xs font-semibold"
                                    }`}
                            >
                                Order Monitoring
                            </span>
                            <span
                                className={`relative z-20 pe-4 transition-colors duration-300 ${isChecked ? "text-white text-xs font-semibold" : "text-black text-xs font-semibold"
                                    }`}
                            >
                                Refunds
                            </span>
                        </label>



                    </div>

                </div>

                <Table tbodyData={dummyData} theadData={theadData} handleEdit={handleEdit}/>

            </div>
        </div>
    )
}

export default OrderMangment