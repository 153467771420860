import React from 'react'

const Table = ({ theadData, tbodyData, handleEdit, handleView }) => {
 
    return (
        <div>
            <div className="overflow-x-auto">
                <table className="w-full border-collapse border border-gray-300 text-sm text-left ">
                    <thead className="bg-gray-100">
                        <tr>
                            {theadData?.map((head, index) => (
                                <th key={index} className="px-4 py-2 border border-gray-300">
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyData?.map((row, rowIndex) => (
                            <tr key={rowIndex} className="hover:bg-gray-50">
                                {theadData.map((head, colIndex) => (
                                    <td key={colIndex} className="px-4 py-2 border border-gray-300">
                                        {head === "Actions" ? (
                                            <div className="flex gap-2">
                                                <button className="text-blue-600 hover:underline" onClick={() => handleEdit(row)}>Edit</button>
                                                <button className="text-blue-600 hover:underline" onClick={() => handleView(row)}>View</button>
                                            </div>
                                        ) : (
                                            row[head]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table