import React from 'react';

const Button = ({ label, onClick, className }) => {
    const defaultClass = 'px-4 py-2 rounded-full font-medium transition bg-gray-200 text-gray-600'
    return (
        <button
            onClick={onClick}
            className={className ? className : defaultClass}
        >
            {label}
        </button>
    );
};

export default Button;
