export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    USER_PASSWORD_UPDATE_REQUEST: "USER_PASSWORD_UPDATE_REQUEST",
    USER_PASSWORD_UPDATE_SUCCESS: "USER_PASSWORD_UPDATE_SUCCESS",
    USER_PASSWORD_UPDATE_FAIL: "USER_PASSWORD_UPDATE_FAIL",

    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_FAILURE: 'USER_LIST_FAILURE',

    BLOCK_UNBLOCK_REQUEST: 'BLOCK_UNBLOCK_REQUEST',
    BLOCK_UNBLOCK_SUCCESS: 'BLOCK_UNBLOCK_SUCCESS',
    BLOCK_UNBLOCK_FAILURE: 'BLOCK_UNBLOCK_FAILURE',

};

