import {
    CHANGE_PASSWORD_REQUEST,CHANGE_PASSWORD_FAILURE,CHANGE_PASSWORD_SUCCESS
} from '../_constants/security.constant'
import { authHeader, APIcallFunction } from '../_helpers';




export const changePassword = (currentPassword, newPassword, confirmPassword) => async (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    const body = {
        currentPassword,
        password: newPassword,
        confirmPassword,
    };

    const credentials = {
        method: 'POST',
        endPoint: '/admin/updatePasswordAdmin',
        header: { 'Authorization': authHeader().Authorization, 'Content-Type': 'application/json' },
        body: body,
    };

    try {
        const response = await APIcallFunction(credentials);
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error });
    }
};