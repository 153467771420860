import React from 'react';

const PopularItem = ({ image, title, description }) => {
  return (
    <div className="flex items-center gap-4 p-2   bg-white">
      <img src={image} alt={title} className="w-40 h-28 rounded object-cover" />
      <div>
        <h3 className="text-lg font-bold">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
        <a href="#" className="text-blue-500 text-sm font-semibold mt-2 inline-block">Explore More</a>
      </div>
    </div>
  );
};

export default PopularItem;
