import{
    CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS
} from '../_constants/security.constant';


const initialState = {
    loading: false,
    message: '',
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, message: action.payload.message };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default authReducer;