import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginHistory } from '../../_actions/login.history.action';

const UserLoginHistory = () => {
    const dispatch = useDispatch();
    const { loginHistory, loading, error } = useSelector((state) => state.loginHistory);

    useEffect(() => {
        dispatch(getLoginHistory({    "sortBy": "createdAt",
        "sortOrder": "desc",
        "pageNo": "1",
        "size": "10" }));
    }, [dispatch]);

    return (
        <div className="container mx-auto mt-6 p-4">
            <h2 className="text-2xl font-bold mb-4">User Login History</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-4 border-b text-left">IP Address</th>
                                <th className="py-2 px-4 border-b text-left">User Agent</th>
                                <th className="py-2 px-4 border-b text-left">Status</th>
                                <th className="py-2 px-4 border-b text-left">Login Time</th>
                                <th className="py-2 px-4 border-b text-left">Failure Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loginHistory.map((entry) => (
                                <tr key={entry._id} className="hover:bg-gray-50">
                                    <td className="py-2 px-4 border-b">{entry.ipAddress}</td>
                                    <td className="py-2 px-4 border-b">{entry.userAgent}</td>
                                    <td className={`py-2 px-4 border-b ${entry.status === 'SUCCESS' ? 'text-green-500' : 'text-red-500'}`}>
                                        {entry.status}
                                    </td>
                                    <td className="py-2 px-4 border-b">{new Date(entry.loginTime).toLocaleString()}</td>
                                    <td className="py-2 px-4 border-b">{entry.failureReason || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UserLoginHistory;