import React, { useEffect, useState } from 'react';
import Table from '../../components/ui/Table/Table/Table';
import Input from '../../components/ui/Input/Input';
import Select from '../../components/ui/Select/Select';
import EditModal from './Modal/EditModal';



function UserManagement() {
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('active');
    const [isEditModal, setIsEditModal] = useState(false)
    const [editData, setEditData] = useState({})
    const theadData = [
        "User ID",
        "Name",
        "Email",
        "Role",
        "Status",
        "Signup Date",
        "Last Login",
        "Orders",
        "Total Spent",
        "Actions"
    ];

    const tbodyData = [
        {
            userId: "0001",
            name: "John Doe",
            email: "john@example.com",
            role: "Customer",
            status: "Active",
            signupdate: "01/12/2023",
            lastlogin: "10/10/2023",
            orders: 12,
            totalspent: "$1,200"
        },
        {
            "user id": "0002",
            name: "Jane Smith",
            email: "jane@example.com",
            role: "Seller",
            status: "Suspended",
            "signup date": "02/15/2022",
            "last login": "09/25/2023",
            orders: 25,
            "total spent": "$3,500"
        },
        {
            "user id": "0003",
            name: "Alice Johnson",
            email: "alice@example.com",
            role: "Admin",
            status: "Active",
            "signup date": "03/21/2021",
            "last login": "10/12/2023",
            orders: 0,
            "total spent": "$0"
        }
    ];

    const filterOptions = [
        { label: 'Active', value: "active" },
        { label: 'Inactive', value: "inactive" }
    ]
    const handleEdit = (data) => {
        setIsEditModal(!isEditModal)
        setEditData(data)
        console.log('dataaaaaaaaaaaa',data)
    }
    const handleOnChange = (e) => {
        const { value, name } = e.target;
        setEditData({
            ...editData, [name]: value
        })
    }
    const handleEditSubmit = (e) => {
        e.preventDefault()
        let updatedData = { name: editData.name }
    }
    const handleClose = () => {
        setIsEditModal(!isEditModal)
        setEditData({})

    }
    const handleView = (data) => {

    }


    function replaceData(data) {
        console.log(data)
        return data.map((row) => ({
            'User ID': row.userId,
            'Name': row.name,
            'Email': row.email,
            "Role": row.role,
            "Status": row.status,
            'Desc': row.description,
            'Signup Date': row.signupdate
        }))
    }
    const dummyData = replaceData(tbodyData)

    return (
        <>
            <div className="container mx-auto p-4 space-y-10">
                <h2 className="text-2xl font-bold mb-4">User Management</h2>
                <div className="p-4 bg-white rounded-2xl  mx-auto w-full space-y-4">
                    <div className='flex justify-between place-items-center'>
                        <div>
                            <h1 className="text-xl font-semibold pb-1">All Customers</h1>
                            <p className='text-[#16C098] pb-8 capitalize'>{filter} Member</p>
                        </div>
                        <div className="flex gap-4 mb-4">
                            <Input
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Select
                                options={filterOptions}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                    </div>
                    <Table theadData={theadData} tbodyData={dummyData} handleEdit={handleEdit} handleView={handleView} />
                </div>
            </div>


            {isEditModal &&
                <EditModal isOpen={isEditModal} onClose={handleClose} editData={editData} handleOnChange={handleOnChange} handleEditSubmit={handleEditSubmit} />
            }
        </>
    );
};

export default UserManagement




